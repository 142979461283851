import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import Vector404 from "../Assets/images/404.png";
import DashboardLayout from "../Components/DashboardLayout";
import { Button } from "../Components/Common/Button";

export default function FourOhFour() {
  const router = useRouter();
  return (
    <div className="min-h-screen bg-[#0f6bac] flex items-center justify-center px-4 py-16 sm:px-6 sm:py-24">
      <div className="bg-white max-w-2xl w-full rounded-xl shadow-md p-8 flex flex-col items-center">
        <div className="max-w-[20vw]">
          <Image alt="404" src={Vector404} />
        </div>

        <main className="sm:flex mt-6 w-full sm:items-start">
          <p className="text-4xl font-bold tracking-tight text-plaved sm:text-5xl">
            404
          </p>
          <div className="sm:ml-6 mt-4 sm:mt-0">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                Sección no disponible
              </h1>
              <p className="mt-1 text-base text-gray-500">
                Revisa la URL en la barra de direcciones y vuelve a intentarlo.
              </p>
            </div>
            <div className="mt-8 flex sm:border-l sm:border-transparent sm:pl-6">
              <Button primary lg onClick={() => router.push("/home")}>
                Volver a PLAVED
              </Button>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}